import { useEffect, useRef, useState } from "react";
import { navigateBack, retrieveItem } from "../../../helpers/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import {
  dynamic_request,
  get_all_languages_query,
  get_all_my_articles_query,
  get_all_my_fact_check_query,
  useDynamicSelector,
} from "../../../services/redux";
import { query_get_author_details } from "../../../services/redux/slices/graphql/graphql_author_details";
import UserProfileTitle from "../user_profile/user_profile_title";
import CustomText from "../custom/custom_text";
import { MdOutlinePendingActions, MdPublish } from "react-icons/md";
import CustomFactCheckCategories from "../custom/custom_fact_check_categories";
import { Button, Skeleton, Tabs, Empty, Select, DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";
import AuthorProfileTitle from "./authotr_profile_title";
import { useDarkMode } from "../contexts/dark_mode_provider";

const { TabPane } = Tabs;

const AuthorDetailsList = () => {
  const { type } = useParams();
  const [list, set_list] = useState([]);
  let current_language = retrieveItem("language");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const top_ref = useRef(null);
  const { is_dark_mode } = useDarkMode();

  const [page_limit, set_page_limit] = useState(12);
  const [page_number, set_page_number] = useState(1);
  const [list_loading, set_list_loading] = useState();
  const [list_pagination, set_list_pagination] = useState();
  const [status, set_type] = useState("published");
  const [redirect_type, set_redirect_type] = useState();
  const [language_id, set_language_id] = useState(
    "839bcc29-853f-4c4c-8700-98fd88558952"
  );
  const [grouped_list, set_grouped_list] = useState({});
  const item_count = Object.values(grouped_list).flat().length;
  const month_wise_item_count = Object.entries(grouped_list).reduce(
    (acc, [key, items]) => {
      acc[key] = items.length;
      return acc;
    },
    {}
  );
  console.log(
    "grouped_list",
    month_wise_item_count,
    grouped_list,
    item_count,
    list_pagination
  );

  const queryParams = new URLSearchParams(location.search);
  const staffProfileId = queryParams.get("staff_profile");
  const userProfileId = queryParams.get("user_profile");
  const record_type = queryParams.get("record_type");

  const {
    items: article_list,
    loading: article_list_loading,
    pagination: article_list_pagination,
  } = useDynamicSelector("get_all_my_article");

  const {
    items: fact_check_list,
    loading: fact_check_list_loading,
    pagination: fact_check_list_pagination,
  } = useDynamicSelector("get_all_my_fact_check");

  const { items: system_languages } = useDynamicSelector(
    "get_all_system_languages"
  );

  useEffect(() => {
    get_languages();
  }, []);

  useEffect(() => {
    if (record_type === "fact_check" && fact_check_list) {
      set_list(fact_check_list);
      set_list_loading(fact_check_list_loading);
      set_list_pagination(fact_check_list_pagination);
    } else if (record_type === "article" && article_list) {
      set_list(article_list);
      set_list_loading(article_list_loading);
      set_list_pagination(article_list_pagination);
    }
  }, [
    record_type,
    article_list,
    article_list_loading,
    article_list_pagination,
    fact_check_list,
    fact_check_list_loading,
    fact_check_list_pagination,
  ]);
  useEffect(() => {
    if (record_type === "fact_check") {
      get_all_my_fact_checks();
      set_redirect_type("Fact Checks");
    } else if (record_type === "article") {
      get_my_article_list();
      set_redirect_type("Articles");
    }
  }, [page_limit, record_type, language_id]);

  useEffect(() => {
    debouncedHandleTitleClick();
  }, []);

  useEffect(() => {
    group_list_by_month(list);
  }, [list]);

  const on_click = () => {
    set_page_limit(page_limit + 6);
  };

  const get_my_article_list = () => {
    let key = [{ key: "get_all_my_article", loading: true }];
    let query = get_all_my_articles_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      language_id: language_id,
      status: "published",
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_my_fact_checks = () => {
    let key = [{ key: "get_all_my_fact_check", loading: true }];
    let query = get_all_my_fact_check_query;
    let variables = {
      page_limit,
      page_number,
      language_id: language_id,
      status: "published",
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_languages = () => {
    let key = [{ key: "get_all_system_languages", loading: true }];
    let query = get_all_languages_query;
    let variables = {};
    dispatch(dynamic_request(key, query, variables));
  };

  const handlePagePosition = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const on_change_language = (value) => {
    set_language_id(value);
  };

  const go_back = () => {
    navigateBack();
  };

  const debouncedHandleTitleClick = debounce(handlePagePosition, 200);

  const group_list_by_month = (items) => {
    const grouped = items.reduce((acc, item) => {
      const month = moment(item.created_date_time).format("MMMM YYYY");
      if (!acc[month]) acc[month] = [];
      acc[month].push(item);
      return acc;
    }, {});
    set_grouped_list(grouped);
  };

  return (
    <div
      style={{ overflow: "hidden" }}
      ref={top_ref}
      className={is_dark_mode ? "content-dark" : ""}
    >
      <CustomText
        class_name={"user_profile_titles"}
        text={redirect_type}
        font_family={"Lato"}
        size={"24px"}
        color={"#140f2d"}
        align={"left"}
        weight={"700"}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "10px",
        }}
      >
        <Select
          style={{ width: "100px", marginRight: "10px" }}
          onChange={on_change_language}
          defaultValue={language_id}
        >
          {system_languages?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>

        <Button onClick={go_back} danger>
          Back
        </Button>
      </div>

      <div className="top_six_article_card">
        {Object.entries(grouped_list).map(([month, items]) => (
          <div key={month}>
            <h3 className="editor_article_list">
              {month} - ({month_wise_item_count[month]})
            </h3>
            <div className="top_six_article">
              {items.map((item) => (
                <CustomFactCheckCategories
                  key={item.id}
                  loading={false}
                  category_image={
                    item?.thumbnail_img === "null"
                      ? BROKEN_IMAGE
                      : item.thumbnail_img
                  }
                  title={item?.title ? item.title : ""}
                  sub_title={item?.subtitle ? item.subtitle : ""}
                  created_date={
                    item
                      ? moment(item.created_date_time).format(`MMM D, YYYY`)
                      : ""
                  }
                  perma_link={item?.perma_link ? item.perma_link : ""}
                  type={record_type ? record_type : ""}
                  name={
                    item.user_profile?.name ||
                    item.staff_profile?.name ||
                    "YouTurn Editorial"
                  }
                  language_id={item?.language_id}
                />
              ))}
            </div>
          </div>
        ))}
        {list_pagination?.total_count === 0 && <Empty description="No data" />}
        {list_loading &&
          Array.from({ length: 6 }, (_, index) => (
            <Skeleton.Input
              key={index}
              className="six-categories-card-second-div-for-skeleton"
              active
            />
          ))}
      </div>
      <div className="see_all_article_button">
        <div className="user_profile_load_more_button">
          {list_pagination?.total_count > item_count && (
            <Button
              className="user_profile_load_button_color"
              onClick={on_click}
            >
              {t("load_more")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorDetailsList;
