import { Avatar } from "antd";
import { startCase } from "lodash";
import { Box, HStack, Image, Menu, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import {
  clearLocalStorage,
  navigate,
  retrieveItem,
  storeItem,
} from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import { FaHome, FaUser } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  dynamic_clear,
  dynamic_request,
  logout_mutation,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";

const AdminHeaderBar = (props) => {
  const dispatch = useDispatch();
  const staff_profile_id = retrieveItem("user_id");
  const { status: log_out_status } = useDynamicSelector("logout");

  const history = useHistory();

  const log_out_api_call = () => {
    let key = [{ key: "logout", loading: true }];
    let query = logout_mutation;
    dispatch(dynamic_request(key, query));
  };

  useEffect(() => {
    if (log_out_status === "Success") {
      dispatch(dynamic_clear("admin_login"));
      storeItem("session_id", null);
      storeItem("roles", []);
      storeItem("role", "");
      storeItem("role_id", "");
      storeItem("page_accesses", []);
      storeItem("page_component_accesses", []);
      clearLocalStorage();
      history.push(ROUTES.ADMIN_LOGIN);
      dispatch(dynamic_clear("logout"));
    }
  }, [log_out_status]);
  const handle_click = () => {
    log_out_api_call();
  };

  const redirect_reset_password_page = () => {
    history.push(ROUTES.FORGOT_PASSWORD);
  };
  const role = retrieveItem("role");

  const on_click_to_view_youturn = () => {
    window.open("https://youturn.in/");
  };
  const handle_redirect_editor_contribution = () => {
    const queryParams = new URLSearchParams();
    if (staff_profile_id) {
      queryParams.set("staff_profile", staff_profile_id);
      queryParams.set("type", "fact_check");
    }
    history.push({
      pathname: `/author/${staff_profile_id}`,
      search: `?${queryParams.toString()}`,
    });
  };

  return (
    <>
      <Box h={"70px"} w="100%" bg="#013366">
        <HStack
          h="90%"
          w="100%"
          bg="#013366"
          space="2"
          justifyContent={"space-between"}
        >
          <Box flex={1} justifyContent="center" pl="4">
            <img
              src={require(`../../../../assets/icons/YT1.png`)}
              width={"230px"}
              height={"50px"}
              style={{ cursor: "pointer" }}
              onClick={on_click_to_view_youturn}
            />
          </Box>
          {"   "}

          <Box my={"auto"}>
            <HStack>
              <Box right="10px" ml="6">
                <Menu
                  w="160"
                  position="absolute"
                  right="0px"
                  top="20px"
                  style={{
                    border: "1px solid #d7d7d7",
                    boxShadow: "-2px 2px #7a7a7a42",
                  }}
                  shouldOverlapWithTrigger={true}
                  placement={"left bottom"}
                  trigger={(triggerProps) => {
                    return (
                      <Pressable
                        alignSelf="center"
                        alignItems="center"
                        variant="solid"
                        {...triggerProps}
                      >
                        <HStack
                          justifyContent={"center"}
                          alignContent={"center"}
                          alignItems={"center"}
                        >
                          {/* <FaHome
                            onClick={handle_home}
                            style={{
                              color: "#ffffff",
                              width: "25px",
                              marginRight: "25px",
                            }}
                          /> */}
                          <Text
                            bold
                            fontSize="md"
                            fontWeight=""
                            color={"white"}
                          >
                            Welcome {role}
                          </Text>
                          <Box right="10px" ml="6">
                            <Avatar
                              round
                              size="25"
                              src={
                                // "https://aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png"
                                <FaUser size={30} />
                              }
                            />
                          </Box>
                        </HStack>
                      </Pressable>
                    );
                  }}
                >
                  <Menu.Item onPress={redirect_reset_password_page}>
                    Reset Password
                  </Menu.Item>
                  <Menu.Item onPress={handle_redirect_editor_contribution}>
                    Profile
                  </Menu.Item>
                  <Menu.Item onPress={handle_click}>Logout</Menu.Item>
                </Menu>
              </Box>
            </HStack>
          </Box>
        </HStack>
      </Box>
    </>
  );
};

export default AdminHeaderBar;
